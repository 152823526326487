<template>
  <div class="container">
    <div class="row">
      <div class="col s12 left-align">
        <h1>Collegare MetaMask alla smart chain di Binance</h1>
        <h2>
          Come collegare MetaMask a  Binance Smart Chain per poter registrare il tuo marchio nel TMPR, il Registro Pubblico dei Marchi?
        </h2>
        <p>In questo video spiegheremo rapidamente come installare e collegare MetaMask  alla smart chain di  Binance per poter registrare il tuo marchio nel TMPR.</p>
        <ul>
          <li>
            <h5>Installare e configurare MetaMask</h5>
            <p>Per prima cosa, dovrai navigare alla <a href="http://https://metamask.io/download" target="_blank">"pagina di download</a> di MetaMask. Una volta lì, seleziona la piattaforma che stai usando e segui i passi.</p>
            <p>Poi, continua con l'installazione specificata dall'applicazione. Vai avanti e clicca su "Crea un wallet".</p>
            <p>E questo è tutto! Ora dovresti essere in grado di vedere il tuo portafoglio, pronto per inviare e ricevere fondi.</p>
          </li>
          <li>
            <h5>Configurare il wallet</h5>
            <p>Avremo bisogno di accedere alle Impostazioni per puntare il portafoglio ai nodi della Smart Chain di Binance.</p>
            <p>Nella pagina "Setting" (configurazione), trova il menu "Networks".</p>
            <p>Clicca su Add Network (Aggiungi rete) in alto a destra per aggiungere manualmente la rete Binance Smart Chain - dato che non è integrata con MetaMask.</p>
          </li>
          <li>
            <h5>Utilizziamo la rete Mainnet</h5>
            <p>Nome della rete: Smart Chain</p>
            <p>Nuovo URL RPC: https://bsc-dataseed.binance.org/</p>
            <p>ChainID: 56</p>
            <p>Symbol: BNB</p>
            <p>Block Explorer URL: https://bscscan.com</p>
            <p>Una volta salvata la rete e tornato alla vista principale, noterai due cose: la rete è stata automaticamente impostata su quella che hai appena inserito, e le unità non sono più denominate in ETH, ma in BNB.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
